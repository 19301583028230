export enum EnumCourierAttendanceStatus {
  ALL = 'ALL',
  ATTEND = 'ATTEND',
  ATTEND_FROM_REQUEST = 'ATTEND_FROM_REQUEST',
  ATTEND_WAITING = 'ATTEND_WAITING',
  ABSENT = 'ABSENT',
  NOT_VERYFY = 'NOT_VERIFY',
}

export enum EnumCourierAttendanceRequestStatus {
  WAITING = 'WAITING',
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
  PRESENT_WITH_APPROVAL = 'PRESENT_WITH_APPROVAL'
}
