



















































































































































































































import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import FileUpload from '@/app/ui/components/FileUpload/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/app/ui/views/Payroll/ManualAdjustSaldo/index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import FileAttachment from '@/app/ui/components/FileUpload/FileAttachment/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon_alt2.vue'
import PayrollController from '@/app/ui/controllers/PayrollController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { CourierAttendanceRequestDetail } from '@/domain/entities/Payroll'
import { Utils } from '@/app/infrastructures/misc'
import { EnumCourierAttendanceRequestStatus } from '@/app/infrastructures/misc/Constants/courierAttendance'
import { SubmitCourierAttendanceApprovalRequest } from '@/data/payload/api/PayrollRequest'

@Component({
  mixins: [validationMixin],
  components: {
    Badge,
    FileUpload,
    RadioButton,
    TextInput,
    Button,
    DataTableV2,
    FileAttachment,
    EditIcon,
    LoadingOverlay,
  },
})
export default class CourierAttendanceDetailAttendanceRequest extends Vue {
  controller = PayrollController
  enumRequestStatus = EnumCourierAttendanceRequestStatus

  form = {
    reason: '',
    status: '',
    files: <string[]>[],
  }
  headers = [
    this.headerCellMapper('Persetujuan oleh', '400px'),
    this.headerCellMapper('Tanggal persetujuan', ''),
  ]

  urls: string[] = []
  isPresent = false
  editMode = false

  tableData: ITableCell[][] = []

  detailData: CourierAttendanceRequestDetail = {
    absenceDate: '',
    attendance: {
      attendanceDate: '',
      attendanceId: undefined,
      meta: {
        proofs: {
          admin: [],
          courier: [],
        },
      },
      status: '',
    },
    attendanceNote: '',
    attendanceTotal: undefined,
    courier: {
      courier3lc: '',
      courierId: undefined,
      courierName: '',
      courierPhone: '',
    },
    dayTotal: undefined,
    histories: [],
    presenceId: undefined,
    status: '',
  }

  created(): void {
    this.fetchDetail()
  }

  @Validations()
  validations() {
    return {
      form: {
        status: { required },
        reason: {
          required: requiredIf((field: { status: string }) => {
            return field.status === this.enumRequestStatus.ABSENT
          }),
        },
      },
    }
  }

  public getFileNameFromUrl(url: string): string {
    return url.split('/')[url.split('/').length - 1]
  }

  public fetchDetail(): void {
    const dateTime = <string>this.$route.params.dateTime
    const courierId = parseInt(<string>this.$route.params.courierId)

    this.controller.fetchCourierRequestAttendanceDetail({
      date: dateTime,
      courierId: courierId,
    })
  }

  public async submitApproval(): Promise<void> {
    const newFiles: string[] = []
    if (this.form.files.length) {
      this.form.files.forEach(async file => {
        if (typeof file !== 'string') {
          const base64 = await Utils.toBase64(file)
          newFiles.push(base64)
        } else {
          newFiles.push(file)
        }

        if (
          newFiles.length === this.form.files.length ||
          !this.form.files.length
        ) {
          this.sendApproval(newFiles)
        }
      })
    } else {
      this.sendApproval(this.form.files)
    }
  }

  public sendApproval(files: string[]): void {
    const dateTime = <string>this.$route.params.dateTime
    const courierId = parseInt(<string>this.$route.params.courierId)

    const payload = new SubmitCourierAttendanceApprovalRequest(
      this.detailData.attendance?.attendanceId,
      courierId,
      dateTime,
      this.form.status.toLocaleLowerCase(),
      files,
      this.form.reason
    )

    this.controller.submitCourierAttendancenApproval(payload)
  }

  public formatStatus(
    status: string
  ): {
    text: string
    color: string
  } {
    switch (status) {
      case this.enumRequestStatus.ABSENT:
        return {
          color: 'error-1',
          text: 'Absen',
        }

      case this.enumRequestStatus.PRESENT:
        return {
          color: 'success',
          text: 'Hadir',
        }

      case this.enumRequestStatus.PRESENT_WITH_APPROVAL:
        return {
          color: 'success',
          text: 'Hadir dari persetujuan',
        }

      default:
        return {
          color: 'warning',
          text: 'Menunggu persetujuan',
        }
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.dataCourierRequestAttendanceDetail')
  onDataCourierRequestAttendanceDetailChanged(
    data: CourierAttendanceRequestDetail
  ): void {
    this.editMode = false
    this.detailData = { ...data }
    this.form.status = <string>data.status
    this.form.reason = <string>data.attendanceNote

    if (data.status === this.enumRequestStatus.PRESENT_WITH_APPROVAL) {
      this.form.status = this.enumRequestStatus.PRESENT
    }

    if (data.status === this.enumRequestStatus.PRESENT) {
      this.isPresent = true
    }

    if (data.status === this.enumRequestStatus.WAITING) {
      this.form.status = ''
      this.editMode = true
    }

    if (data.histories?.length) {
      this.tableData = data.histories.map(history => [
        this.tableCellMapper(<string>history.username, '400px'),
        this.tableCellMapper(
          Utils.formatTimeZone(
            Utils.formatDateWithIDLocale(
              <string>history.createdAt,
              'DD MMM YYYY, HH:mm Z'
            )
          ),
          ''
        ),
      ])
    }

    if (data.absenceDate) {
      this.detailData.absenceDate = Utils.formatTimeZone(
        Utils.formatDateWithIDLocale(
          data.absenceDate,
          'dddd, DD MMM YYYY, HH:mm Z'
        )
      )
    }

    if (data.attendance?.meta) {
      this.urls = <string[]>(
        data.attendance.meta?.proofs?.courier?.map(proof => proof.url)
      )
      this.form.files = <string[]>(
        data.attendance.meta.proofs?.admin?.map(proof => proof.url)
      )
    }

    if (!data.histories?.length) {
      this.tableData = [
        [this.tableCellMapper('-', '400px'), this.tableCellMapper('-', '')],
      ]
    }
  }

  @Watch('controller.isSubmitCourierAttendanceApprovalSuccess')
  onIsSubmitCourierAttendanceApprovalSuccess(data: boolean): void {
    if (data) {
      this.fetchDetail()
      this.controller.setIsSubmitCourierAttendanceApprovalSuccess(false)
    }
  }
}
